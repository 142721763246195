import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { PrivacyIcon, SecurityIcon, SpeedIcon, StreamingIcon } from '@components/icon';
import { Text } from '@components';
import { SERVER_COUNT } from '@shared/constants/server-locations';
import { formatLink } from '@shared/format-helpers';
import { getFetchedImage } from '@shared/image-helpers';

import { GA4_EVENTS } from './google';

import streaming from '@images/features/streaming.png';
import privacy from '@images/features/privacy.png';
import speed from '@images/features/speed.png';
import security from '@images/features/security.png';
import streamingDark from '@images/features/streaming-dark.png';
import privacyDark from '@images/features/privacy-dark.png';
import speedDark from '@images/features/speed-dark.png';
import securityDark from '@images/features/security-dark.png';

const ListItem = styled.li`
  margin-bottom: 16px;
`;

const FEATURES_TAB_IMAGES = {
  light: {
    streaming,
    security,
    speed,
    privacy,
  },
  dark: {
    streaming: streamingDark,
    security: securityDark,
    speed: speedDark,
    privacy: privacyDark,
  },
};

const FEATURES_TAB_IMAGE_PATHS = {
  light: {
    streaming: 'features/streaming.png',
    security: 'features/security.png',
    speed: 'features/speed.png',
    privacy: 'features/privacy.png',
  },
  dark: {
    streaming: 'features/streaming-dark.png',
    security: 'features/security-dark.png',
    speed: 'features/speed-dark.png',
    privacy: 'features/privacy-dark.png',
  },
};

export const FEATURES_TAB_DATA = ({ t, variant, images }) => [
  {
    labelProps: { label: t('featureTabs:streaming.title'), image: StreamingIcon, variant },
    contentProps: {
      content: {
        title: t('featureTabs:streaming.title'),
        description: (
          <ul>
            <ListItem>
              <Text.Body1>
                <Trans
                  i18nKey="featureTabs:streaming.listItems.seamlessStreaming"
                  components={[<strong key={0} />]}
                />
              </Text.Body1>
            </ListItem>
            <ListItem>
              <Text.Body1>
                <Trans
                  i18nKey="featureTabs:streaming.listItems.noBandwidth"
                  components={[<strong key={0} />]}
                />
              </Text.Body1>
            </ListItem>
          </ul>
        ),
        button: {
          title: t('common:actions.getAtlasVpn'),
          link: formatLink(t('navigation:paths.get')),
          buttonProps: { customEvent: { eventName: GA4_EVENTS.featuresBlockCtaClick } },
        },
        image: !images
          ? FEATURES_TAB_IMAGES[variant].streaming
          : getFetchedImage(images, FEATURES_TAB_IMAGE_PATHS[variant].streaming),
      },
    },
  },
  {
    labelProps: { label: t('featureTabs:security.title'), image: SecurityIcon, variant },
    contentProps: {
      content: {
        title: t('featureTabs:security.title'),
        description: (
          <React.Fragment>
            <ul>
              <ListItem>
                <Text.Body1>
                  <Trans
                    i18nKey="featureTabs:security.listItems.seamlessStreaming"
                    components={[<strong key={0} />]}
                  />
                </Text.Body1>
              </ListItem>
              <ListItem>
                <Text.Body1>
                  <Trans
                    i18nKey="featureTabs:security.listItems.blockMalwareAndAds"
                    components={[<strong key={0} />]}
                  />
                </Text.Body1>
              </ListItem>
              <ListItem>
                <Text.Body1>
                  <Trans
                    i18nKey="featureTabs:security.listItems.dataBreachMonitor"
                    components={[<strong key={0} />]}
                  />
                </Text.Body1>
              </ListItem>
            </ul>

            <Text.Body3 as="span" display="block" mt={16}>
              {t('common:wireGuardTrademark')}
            </Text.Body3>
          </React.Fragment>
        ),
        button: {
          title: t('common:actions.getAtlasVpn'),
          link: formatLink(t('navigation:paths.get')),
          buttonProps: { customEvent: { eventName: GA4_EVENTS.featuresBlockCtaClick } },
        },
        image: !images
          ? FEATURES_TAB_IMAGES[variant].security
          : getFetchedImage(images, FEATURES_TAB_IMAGE_PATHS[variant].security),
      },
    },
  },
  {
    labelProps: { label: t('featureTabs:speed.title'), image: SpeedIcon, variant },
    contentProps: {
      content: {
        title: t('featureTabs:speed.title'),
        description: (
          <ul>
            <ListItem>
              <Text.Body1>
                <Trans
                  i18nKey="featureTabs:speed.listItems.speed"
                  components={[<strong key={0} />]}
                  values={{ serverCount: SERVER_COUNT }}
                />
              </Text.Body1>
            </ListItem>
            <ListItem>
              <Text.Body1>
                <Trans
                  i18nKey="featureTabs:speed.listItems.p2p"
                  components={[<strong key={0} />]}
                />
              </Text.Body1>
            </ListItem>
            <ListItem>
              <Text.Body1>
                <Trans
                  i18nKey="featureTabs:speed.listItems.bestLocation"
                  components={[<strong key={0} />]}
                />
              </Text.Body1>
            </ListItem>
          </ul>
        ),
        button: {
          title: t('common:actions.getAtlasVpn'),
          link: formatLink(t('navigation:paths.get')),
          buttonProps: { customEvent: { eventName: GA4_EVENTS.featuresBlockCtaClick } },
        },
        image: !images
          ? FEATURES_TAB_IMAGES[variant].speed
          : getFetchedImage(images, FEATURES_TAB_IMAGE_PATHS[variant].speed),
      },
    },
  },
  {
    labelProps: { label: t('featureTabs:privacy.title'), image: PrivacyIcon, variant },
    contentProps: {
      content: {
        title: t('featureTabs:privacy.title'),
        description: (
          <ul>
            <ListItem>
              <Trans
                i18nKey="featureTabs:privacy.listItems.privateAccess"
                components={[<strong key={0} />]}
              />
            </ListItem>
            <ListItem>
              <Trans
                i18nKey="featureTabs:privacy.listItems.safeSwap"
                components={[<strong key={0} />]}
              />
            </ListItem>
            <ListItem>
              <Trans
                i18nKey="featureTabs:privacy.listItems.noLogs"
                components={[<strong key={0} />]}
              />
            </ListItem>
          </ul>
        ),
        button: {
          title: t('common:actions.getAtlasVpn'),
          link: formatLink(t('navigation:paths.get')),
          buttonProps: { customEvent: { eventName: GA4_EVENTS.featuresBlockCtaClick } },
        },
        image: !images
          ? FEATURES_TAB_IMAGES[variant].privacy
          : getFetchedImage(images, FEATURES_TAB_IMAGE_PATHS[variant].privacy),
      },
    },
  },
];
