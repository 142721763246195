import React, { useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Box, Grid } from '@core';
import { ContentBlock, SectionContainerSC, TabsSlider, Text } from '@components';
import { FEATURES_TAB_DATA } from '@shared/constants/features';
import { isLTR } from '@shared/translation-helpers';

const LabelComponent = ({ isOpen, label, image: FeatureIcon, variant }) => (
  <Box
    position="relative"
    bottom="-3px"
    borderBottom="4px solid"
    borderColor={isOpen ? 'primary' : 'transparent'}
    height="100%"
    mx={32}
  >
    <Grid.Container mb={24}>
      <Grid.Row>
        <Grid.Col display="flex" justifyContent="center" height={36}>
          <FeatureIcon isActive={isOpen} variant={variant} />
        </Grid.Col>
        <Grid.Row mt={16} alignItems="center" justifyContent="center">
          <Text.Body2Strong textAlign="center" whiteSpace="nowrap">
            {label}
          </Text.Body2Strong>
        </Grid.Row>
      </Grid.Row>
    </Grid.Container>
  </Box>
);

const ContentComponent = ({ content }) => (
  <Box mt={80} minWidth="100%" maxWidth="100%">
    <ContentBlock {...content} />
  </Box>
);

const Wrapper = styled.div`
  width: 100%;
  padding-bottom: 4px;
  overflow-x: auto;

  scrollbar-width: none; /* Hide scrollbar for Firefox */
  ::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const FeaturesTabs = ({ title, description, tabsData, variant = 'light', sectionProps }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const wrapperRef = useRef();

  return (
    <SectionContainerSC
      data-section-id="FeaturesTabs"
      title={title}
      description={description}
      {...sectionProps}
    >
      <TabsSlider
        headerWrapper={({ children }) => (
          <Wrapper ref={wrapperRef}>
            <Box
              minWidth={832}
              borderBottom="2px solid"
              borderColor={
                (variant === 'dark' && 'darkBlue_80') || (variant === 'light' && '#ECEDED')
              }
              display="flex"
            >
              <Box mx={{ md: 'auto' }} display="flex">
                {children}
              </Box>
            </Box>
          </Wrapper>
        )}
        tabsData={tabsData || FEATURES_TAB_DATA({ t, variant })}
        labelComponent={LabelComponent}
        contentComponent={ContentComponent}
        onOpen={(tabIndex) =>
          (wrapperRef.current.scrollLeft = tabIndex * (isLTR(language) ? 200 : -200))
        }
        hideSliderIndicator={true}
      />
    </SectionContainerSC>
  );
};

export default FeaturesTabs;
